/**
 * @file standalone/expandable_video_player.scss
 *
 * \brief Library file - shared styles for expandable video player
 *
 */

@import '../theme-bootstrap';

.disable-scrolling {
  overflow-y: hidden;
  .content-block-collapsible__content,
  .content-block-collapsible,
  .content-block-formatter,
  .content-block-formatter__items,
  .basic-carousel-formatter,
  .slick-list,
  .slick-track,
  .content-block--tout {
    // Used !important to override the transform style
    transform: none !important; // stylelint-disable-line declaration-no-important
  }
}

.expandable_video_grid {
  cursor: pointer;
  position: relative;
  .mantle-media-asset img {
    width: 100%;
  }
  .expandable__play {
    #{$rdirection}: 22px;
    color: $color-white;
    bottom: 22px;
    position: absolute;
    z-index: 1;
    @include breakpoint($medium-up) {
      #{$rdirection}: 44px;
      bottom: 44px;
    }
    .icon--plus {
      display: block;
      font-size: 20px;
    }
  }
}

.disable-media-tile-click {
  .expandable_video_grid {
    cursor: default;
  }
  .expandable__play,
  .mpp-tout__icon {
    .icon {
      display: none;
    }
  }
}

.content-block-large__inner {
  .expandable_video_grid {
    .expandable__play {
      @include breakpoint($medium-up) {
        #{$rdirection}: 78px;
        bottom: 44px;
        z-index: 1;
      }
    }
    .content-block-large__image-animated--5 + .expandable__play {
      display: none;
    }
  }
}

.video__overlay-outter {
  #{$ldirection}: 0;
  background-color: $black;
  display: none;
  height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: z('video-module', 'video-overlay');
}

.video__overlay {
  #{$ldirection}: 0;
  background-color: $color-black;
  display: none;
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: z('video-module', 'video-overlay');
  .videojs-video {
    padding-top: 49%;
  }
  @include breakpoint($medium-up) {
    #{$ldirection}: 2%;
    height: 87%;
    top: 7%;
    width: 96%;
  }
  .videojs-video__player,
  .videojs-video__landing,
  .video-details__video-wrapper,
  .videojs-video {
    position: fixed;
    @include breakpoint($medium-up) {
      #{$ldirection}: 2%;
      height: 87%;
      padding: 0;
      top: 7%;
      width: 96%;
    }
  }
  .video__overlay-close {
    #{$rdirection}: 26px;
    color: $color-white;
    cursor: pointer;
    display: flex;
    font-size: 15px;
    position: absolute;
    top: 33px;
    z-index: z('video-module', 'close-icon');
    @include breakpoint($medium-up) {
      #{$rdirection}: 0;
      top: -33px;
    }
    &-cta {
      position: absolute;
      #{$rdirection}: 26px;
      top: 2px;
    }
    &-icon {
      @include icon('close');
    }
  }
  .video-block__media-video {
    height: auto;
    width: 100%;
  }
  .videojs-video__landing__image,
  .vjs-poster {
    // Used !important to override the inline style
    display: none !important; // stylelint-disable-line declaration-no-important
  }
}
